/* raleway-regular - latin */
/* raleway-100 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/raleway-v27-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/raleway-v27-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/raleway-v27-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/raleway-v27-latin-100.woff') format('woff'), /* Modern Browsers */
       url('./fonts/raleway-v27-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/raleway-v27-latin-100.svg#Raleway') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* raleway-200 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/raleway-v27-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/raleway-v27-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/raleway-v27-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/raleway-v27-latin-200.woff') format('woff'), /* Modern Browsers */
       url('./fonts/raleway-v27-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/raleway-v27-latin-200.svg#Raleway') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* raleway-300 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/raleway-v27-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/raleway-v27-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/raleway-v27-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/raleway-v27-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./fonts/raleway-v27-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/raleway-v27-latin-300.svg#Raleway') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/raleway-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/raleway-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/raleway-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/raleway-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/raleway-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/raleway-v27-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* raleway-500 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/raleway-v27-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/raleway-v27-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/raleway-v27-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/raleway-v27-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./fonts/raleway-v27-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/raleway-v27-latin-500.svg#Raleway') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* raleway-600 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/raleway-v27-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/raleway-v27-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/raleway-v27-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/raleway-v27-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./fonts/raleway-v27-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/raleway-v27-latin-600.svg#Raleway') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/raleway-v27-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/raleway-v27-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/raleway-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/raleway-v27-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/raleway-v27-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/raleway-v27-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* raleway-800 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/raleway-v27-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/raleway-v27-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/raleway-v27-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/raleway-v27-latin-800.woff') format('woff'), /* Modern Browsers */
       url('./fonts/raleway-v27-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/raleway-v27-latin-800.svg#Raleway') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* raleway-900 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/raleway-v27-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/raleway-v27-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/raleway-v27-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/raleway-v27-latin-900.woff') format('woff'), /* Modern Browsers */
       url('./fonts/raleway-v27-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/raleway-v27-latin-900.svg#Raleway') format('svg'); /* Legacy iOS */
  font-display: swap;
}


body {
  margin: 0;
  font-family: "Raleway", sans-serif;
}



